import React, { forwardRef } from 'react';
import { HorizontalLine } from './Lines';
import { Section } from '.';
import './FeaturedQuote.scss';

const FeaturedQuote = forwardRef(({ className, quoteText }, ref) => (
  <Section className={`featured-quote ${className ? ` ${className}` : ''}`} ref={ref}>
    <HorizontalLine transformOrigin="0 50%" />
    <p className="quote-text">{quoteText}</p>
    <HorizontalLine transformOrigin="100% 50%" />
  </Section>
));
FeaturedQuote.displayName = 'FeaturedQuote';

export default FeaturedQuote;
