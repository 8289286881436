export const isClient = typeof window !== 'undefined';

export const toKebabCase = str => str.replace(/\s+/g, '-').toLowerCase();

export const trimText = (string, maxLength) => {
  if (!string || string === '') return '...';
  // Trim content to length
  const trimmedContent = string.substring(0, maxLength);
  // Make sure excerpt doesn't split a word in half
  const excerpt = trimmedContent.substring(0, trimmedContent.lastIndexOf(' '));
  // Return with added ellipsis
  return `${excerpt}...`;
};

export const shuffleArray = (array) => {
  const shuffledArray = [...array];
  let counter = shuffledArray.length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    const index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter -= 1;

    // And swap the last element with it
    const temp = shuffledArray[counter];
    shuffledArray[counter] = shuffledArray[index];
    shuffledArray[index] = temp;
  }

  return shuffledArray;
};


export const insertElementEveryN = (originalArray, insertArray, n) => {
  const newArray = [...originalArray];

  let insertionIndex = 0;
  let index = n;

  while (index <= newArray.length && insertionIndex <= insertArray.length - 1) {
    newArray.splice(index, 0, insertArray[insertionIndex]);
    index += n;
    insertionIndex += 1;
  }

  return newArray;
};

export const colorMap = [
  {
    name: 'White',
    hex: '#FFFFFF',
    altHex: '#000000',
  },
  {
    name: 'Purple',
    hex: '#4D3389',
    altHex: '#FFFFFF',
  },
  {
    name: 'Yellow',
    hex: '#F8CE67',
    altHex: '#CF6038',
  },
  {
    name: 'Cyan',
    hex: '#7CC9E4',
    altHex: '#245E99',
  },
  {
    name: 'Orange',
    hex: '#EA943D',
    altHex: '#CF3B31',
  },
  {
    name: 'Blue',
    hex: '#4FB3CC',
    altHex: '#245E99',
  },
  {
    name: 'Lime',
    hex: '#C7D551',
    altHex: '#3D6D33',
  },
  {
    name: 'Green',
    hex: '#3D6D33',
    altHex: '#C7D551',
  },
  {
    name: 'Navy',
    hex: '#245E99',
    altHex: '#4FB3CC',
  },
  {
    name: 'Red',
    hex: '#CF3B31',
    altHex: '#EA943D',
  },
];

export const findColor = colorName => colorMap.find(color => color.name === colorName);

export const getSeededRandom = seedStr => seedrandom(seedStr)();
