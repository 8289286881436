import React, {
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from 'react';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
import useComposeRefs from '../../utils/composeRefs';
import useImperativeTimelineHandle from '../../hooks/useImperativeTimelineHandle';
import './Lines.scss';

const Line = forwardRef((props, ref) => {
  const {
    className,
    animatedWhenInView = true,
    style,
  } = props;
  const lineRef = useRef();
  const [inViewRef, inView] = useInView({ threshold: 0.2, triggerOnce: true });

  const createTimeline = useCallback(() => {
    const timeline = gsap.timeline();
    timeline.to(lineRef.current, { scaleX: '1', duration: 1.2, ease: 'sine.inOut' });
    return timeline;
  }, []);
  useImperativeTimelineHandle(ref, createTimeline, []);
  useEffect(() => {
    if (animatedWhenInView && inView) {
      createTimeline();
    }
  }, [inView]);

  return (
    <div className={`line ${className ? ` ${className}` : ''}`} ref={useComposeRefs(lineRef, inViewRef)} style={style} />
  );
});
Line.displayName = 'Line';

export const HorizontalLine = forwardRef(({ className, transformOrigin }, ref) => (
  <Line className={`line horizontal${className ? ` ${className}` : ''}`} ref={ref} style={{ transformOrigin }} />
));
HorizontalLine.displayName = 'HorizontalLine';
