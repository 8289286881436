import React, { useState, useCallback, useRef } from 'react';
import gsap from 'gsap';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useInView } from 'react-intersection-observer';
import { SplitWordsAnimation, Section, FeaturedQuote } from './common';
import { StarField, UniverseGrid } from '.';
import './ManyQuestions.scss';

const scrollId = 'many-questions-scroll-anchor';

const ManyQuestions = () => {
  const [hideUniverse, setHideUniverse] = useState(false);
  const sectionRef = useRef();

  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true });

  const refreshStarfield = useCallback(() => {
    if (sectionRef.current) {
      scrollTo(`#${scrollId}`);
      gsap.to(sectionRef.current, {
        opacity: 0,
        ease: 'expo.out',
        duration: 0.3,
        onComplete: () => {
          setHideUniverse(true);
          setTimeout(() => {
            setHideUniverse(false);
            gsap.to(sectionRef.current, {
              opacity: 1,
              ease: 'expo.out',
              duration: 1,
              delay: 0.3,
            });
          }, 300);
        },
      });
    }
  }, []);

  return (
    <div id={scrollId}>
      <Section>
        <div className="many-questions-animation" ref={sectionRef}>
          <div className="animation-container">
            {!hideUniverse && (
              <>
                <UniverseGrid />
                <StarField />
              </>
            )}
          </div>
        </div>
        <button
          className="refresh-button"
          type="button"
          aria-label="Generate a new universe"
          disabled={hideUniverse}
          onClick={() => refreshStarfield()}
        >
          <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.888 7.771a1.267 1.267 0 01-1.259 1.106h-.17l-3.954-.519A20.411 20.411 0 1120.43.5a1.276 1.276 0 110 2.551A17.859 17.859 0 1034.293 9.66l-.212 4.933a1.276 1.276 0 01-1.267 1.216h-.06a1.258 1.258 0 01-1.216-1.327l.34-7.917a.127.127 0 010-.06.663.663 0 010-.127v-.179a.119.119 0 010-.051 1.136 1.136 0 01.196-.315c.076-.088.165-.166.263-.23a1.22 1.22 0 01.179-.101l.17-.06h.502l7.475.995a1.276 1.276 0 011.225 1.335z" />
          </svg>
        </button>
      </Section>
      <Section className="many-questions-text-section" hasGrid>
        <div className="title">
          <div className="title-container" ref={inViewRef}>
            <SplitWordsAnimation animationDelay={0} show={inView}>
              <h2>
                Many Worlds,
                <br />
                Many Questions
              </h2>
            </SplitWordsAnimation>
          </div>
        </div>
        <div className="column column-left">
          <p>Firstly, is there a way to prove the existence of universes outside of our own?  The answer remains open, but it is likely that there is no way to verify the existence of other universes.</p>
          <p>Some, like physicist Michio Kaku, liken our situation to a fish swimming in a pond.  We can move forward and back, side to side, but never up out of the water. If someone were standing beside the pond, watching us, we’d have no idea they were there.</p>
          <p>To us, that little pond is an entire universe.</p>
        </div>
        <div className="column column-right">
          <p>But, if someone was to reach down and lift us out of the pond. We might see that what you thought was the entire world is only a small pool. We might see other ponds. We might see things we’ve never had to even think about before - like trees, or the sky above.</p>
          <p>Perhaps the multiverse is something that exists, but it is beyond our telescopes, beyond our cosmological horizon and what is observable and measurable.</p>
        </div>
      </Section>
      <FeaturedQuote
        quoteText="Perhaps the multiverse is something that exists, but it is beyond our telescopes, beyond our cosmological horizon and what is observable and measurable."
      />
      <Section className="many-questions-text-section" hasGrid>
        <div className="column column-left">
          <p>Critics of the existence of a multiverse, like Paul Davies and George Ellis, acknowledge that phenomena likely does exist beyond what we can physically observe or measure. However, they warn that it is a slippery slope to accept theories on faith as opposed to scientific verficiation.</p>
        </div>
        <div className="column column-right">
          <p>That invoking an infinity of unseen universes to explain the unusual features of the one we do see is perhaps just as ad hoc as invoking an unseen Creator.  Nothing is wrong with scientifically based philosophical speculation, which is what multiverse proposals are, but they warn that we should name it for what it is.</p>
        </div>
      </Section>
      <FeaturedQuote
        quoteText="The multiverse theory may be dressed up in scientific language, but in essence, it requires the same leap of faith."
      />
    </div>
  );
};

export default ManyQuestions;
