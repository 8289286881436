import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import composeRefs from '../../utils/composeRefs';
import wrapAll from '../../utils/wrapAll';
import unwrap from '../../utils/unwrap';
import useWebFontLoaded from '../../hooks/useWebFontLoaded';
import './SplitWordsAnimation.scss';

gsap.registerPlugin(SplitText);

const useSplitWordsAnimation = ({ animationDelay, linesOnly = false, show }) => {
  const [fontsReady, setFontsReady] = useState(false);
  const fontsReadyRef = useWebFontLoaded(useCallback(() => {
    setFontsReady(true);
  }, []));

  const locals = useRef({}).current;

  const mountRef = useCallback((el) => {
    if (el) {
      if (!fontsReady) {
        return;
      }
      locals.split = new SplitText(el, { type: linesOnly ? 'lines' : 'words,lines' });
      gsap.set(locals.split.lines, { overflow: 'hidden' });
      if (linesOnly) {
        locals.targets = locals.split.lines.map(
          line => wrapAll(line.childNodes, document.createElement('div')),
        );
      } else {
        locals.targets = locals.split.words;
      }
      // gsap.set(locals.targets, { yPercent: 100, visibility: 'hidden' });
      gsap.set(el, { visibility: 'visible' });
      locals.el = el;
    } else if (locals.split) {
      if (linesOnly) {
        locals.targets.forEach((innerLine) => {
          unwrap(innerLine);
        });
      }
      locals.split.revert();
      delete locals.targets;
      delete locals.split;
      delete locals.el;
    }
  }, [locals, linesOnly, fontsReady]);

  const shouldReveal = show && fontsReady;

  useEffect(() => {
    if (!locals.el) {
      return;
    }

    if (shouldReveal) {
      gsap.to(
        locals.targets,
        {
          ease: 'Cubic.easeInOut',
          y: 0,
          stagger: 0.06,
          duration: 1.2,
          delay: animationDelay,
          visibility: 'visible',
        },
      );
    }
  }, [shouldReveal]);

  return composeRefs(fontsReadyRef, mountRef);
};

const SplitWordsAnimation = (props) => {
  const {
    show,
    children,
    animationDelay,
    srOnlyContent,
    linesOnly = false,
    screenReaderClone = true,
  } = props;
  const child = React.Children.only(children);
  const content = child.props.children;
  const ref = useSplitWordsAnimation({ linesOnly, animationDelay, show });
  return React.cloneElement(child, {}, (
    <>
      {screenReaderClone && <div className="srOnly">{srOnlyContent || content}</div>}
      <div className="split-words" ref={ref} aria-hidden="true">
        {content}
      </div>
    </>
  ));
};

export default SplitWordsAnimation;
