import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
import composeRefs from '../utils/composeRefs';
import { brandColors } from '../utils/color';
import './RevealingUniverse.scss';

const animationClasses = [
  'float-animation',
  'rotate-animation',
];

const MIN_TILT = -35;
const MAX_TILT = 35;

const MIN_SIZE_PERCENTAGE = 25;
const MAX_SIZE_PERCENTAGE = 100;

const MIN_OFFSET_Y = -100;
const MAX_OFFSET_Y = 100;

const INITIAL_ATTRIBUTES = {
  color: brandColors[0],
  tilt: 0,
  sizePercent: 100,
  // animation: animationClasses[0],
  offsetY: 0,
};

const RevealingUniverse = () => {
  const ref = useRef();
  const svgRef = useRef();
  const pathRef = useRef();

  const [attributes, setAttributes] = useState(INITIAL_ATTRIBUTES);
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true });

  useEffect(() => {
    // Initialising universe
    const randColorsIndex = Math.floor(gsap.utils.random(0, brandColors.length));
    // const randAnimationIndex = Math.floor(gsap.utils.random(0, animationClasses.length));
    const randTilt = Math.floor(gsap.utils.random(MIN_TILT, MAX_TILT));
    const randSizePercent = Math.floor(gsap.utils.random(MIN_SIZE_PERCENTAGE, MAX_SIZE_PERCENTAGE));
    const randYOffset = Math.floor(gsap.utils.random(MIN_OFFSET_Y, MAX_OFFSET_Y));

    setAttributes({
      color: brandColors[randColorsIndex],
      tilt: randTilt,
      // animation: animationClasses[randAnimationIndex],
      sizePercent: randSizePercent,
      offsetY: randYOffset,
    });

    gsap.set(svgRef.current, {
      scale: 0,
      transformOrigin: '50% 50%',
    });
    gsap.set(pathRef.current, {
      drawSVG: '0%',
    });
  }, []);

  useEffect(() => {
    if (inView) {
      gsap.to(svgRef.current, {
        scale: 1,
        ease: 'expo.out',
        duration: 0.8,
        delay: 0.5,
      });
      gsap.to(pathRef.current, {
        drawSVG: '100%',
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, [inView]);

  return (
    <div
      className="universe"
      ref={composeRefs(ref, inViewRef)}
      style={{
        transform: `rotate(${attributes.tilt}deg)`,
      }}
    >
      <svg
        ref={svgRef}
        style={{
          width: `${attributes.sizePercent}%`,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 182 55"
      >
        <path
          ref={pathRef}
          d="M178.995 10.875c.441.729-1.586 7.082-2.643 9.181-1.321 2.623-7.978 11.062-25.158 16.746-17.181 5.683-61.626 15.605-85.855 15.605-17.23 0-53.352-.74-61.722-15.605C-3.079 24.91 34.062 12.478 52.564 8.689c16.887-3.498 53.568-8.57 70.484-4.374 13.657 3.388 15.419 4.069 20.265 11.064 3.876 5.596-20.265 16.917-37.005 21.423-21.145 3.8-65.598 6.645-72.294 0-6.696-6.646 7.243-12.667 15.026-14.999 7.048-2.04 20.373-4.715 33.92-6.424 11.454-1.445 21.586-3.194 32.159 0 6.649 2.009-3.524 7.883-4.846 7.737-9.398 2.915-22.467 4.108-29.515 4.808"
          stroke={attributes.color}
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

export default RevealingUniverse;
