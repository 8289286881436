import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import get from 'lodash/get';
import ScrollTrigger from 'gsap/ScrollTrigger';
import * as d3 from 'd3';
import { Section } from './common';
import { brandColors } from '../utils/color';
import useIsMobile from '../hooks/useIsMobile';
import './StarField.scss';

const vh = coef => window.innerHeight * (coef / 100);

const StarField = () => {
  const isMobile = useIsMobile();
  const circleMinRad = 1;
  const circleMaxRad = 10;
  const svgWidth = 700;
  const svgHeight = isMobile ? 2450 : 1750;
  const numStars = 24;
  const starData = new Array(numStars).fill(undefined).map((val, idx) => ({
    id: idx,
  }));

  const containerRef = useRef();
  const svgRef = useRef();

  /* The useEffect Hook is for running side effects outside of React,
  for instance inserting elements into the DOM using D3 */
  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);

      // Bind D3 data
      const update = svg
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`);

      const circles = svg
        .selectAll('circle')
        .data(starData)
        .join('circle')
        .attr('class', 'points')
        .attr('r', () => gsap.utils.random(circleMinRad, circleMaxRad, 1))
        .attr('cx', () => (circleMaxRad + Math.floor(Math.random() * (svgWidth - circleMaxRad * 2))))
        .attr('cy', () => (200 + circleMaxRad + Math.floor(Math.random() * (svgHeight - circleMaxRad * 2))))
        .style('fill', () => brandColors[Math.floor(Math.random() * brandColors.length)])
        .style('opacity', 0.7);

      // Remove old D3 elements
      update.exit()
        .remove();
    }
  }, [starData, isMobile, svgRef.current]);

  useEffect(() => {
    if (svgRef.current.children) {
      // Create parallax effect
      gsap.utils.toArray(svgRef.current.children).forEach((circle) => {
        const size = get(circle, ['attributes', 'r', 'value']);
        const parallaxTl = gsap.timeline();
        const parallaxScrollTrigger = ScrollTrigger.create({
          trigger: containerRef.current,
          start: `top-=${vh(100)}`,
          end: `bottom top`,
          scrub: 1,
          animation: parallaxTl,
        });
        const rateOfChange = Math.floor(300 * (size / circleMaxRad));
        parallaxTl.fromTo([circle],
          { y: 0 },
          { y: `-${rateOfChange}px` },
          0);
        return () => {
          parallaxTl.kill();
          parallaxScrollTrigger.kill();
        };
      });
    }
  }, []);

  return (
    <Section className="starfield" ref={containerRef}>
      <div className="svg-container">
        <svg
          className="d3-component"
          ref={svgRef}
        />
      </div>
    </Section>
  );
};

export default React.memo(StarField);
