import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Section, SplitWordsAnimation } from './common';
import './Sources.scss';

const sources = [
  {
    title: 'Dark Matter - Blake Crouch',
    url: 'https://www.goodreads.com/book/show/27833670-dark-matter',
  },
  {
    title: 'Reddit: Explain Like I’m Five - Schrodinger’s Cat',
    url: 'https://www.reddit.com/r/explainlikeimfive/comments/1csq0o/eli5schrodingers_cat/',
  },
  {
    title: 'Wikipedia - Many-worlds interpretation',
    url: 'https://en.wikipedia.org/wiki/Many-worlds_interpretation',
  },
  {
    title: "Wikipedia - Schrödinger's cat",
    url: 'https://en.wikipedia.org/wiki/Schr%C3%B6dinger%27s_cat',
  },
  {
    title: 'Multiverse Me - Rowan Hooper',
    url: 'https://www.newscientist.com/article/mg22329880-400-multiverse-me-should-i-care-about-my-other-selves/',
  },
  {
    title: 'The Multiverse Idea Is Rotting Culture - Sam Kriss',
    url: 'https://www.theatlantic.com/science/archive/2016/08/the-multiverse-as-imagination-killer/497417/',
  },
];

const Sources = () => {
  const [inViewRef, inView] = useInView({ threshold: 0.2, triggerOnce: true });
  return (
    <div className="sources">
      <Section hasGrid>
        <div className="title">
          <div className="title-container" ref={inViewRef}>
            <SplitWordsAnimation animationDelay={0} show={inView}>
              <h2>Sources</h2>
            </SplitWordsAnimation>
          </div>
        </div>
        <ul className="source-list">
          {sources && sources.map(source => (
            <li key={source.url} className="source">
              <a href={source.url} target="_blank" rel="noopener noreferrer">
                <span>{source.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </Section>
    </div>
  );
};

export default Sources;
