import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import { SplitWordsAnimation, Section } from './common';
import './Intro.scss';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const vh = coef => window.innerHeight * (coef / 100);

const Intro = () => {
  const lineScrollTriggerRef = useRef();
  const branchScrollTriggerRef = useRef();
  const tailScrollTriggerRef = useRef();
  const lineAnimationRef = useRef();
  const branchAnimationRef = useRef();
  const tailAnimationRef = useRef();
  const lineSvgRef = useRef();
  const branchContainerRef = useRef();
  const tailSvgRef = useRef();

  const [showTitle, setShowTitle] = useState(false);
  const [decisionMade, setDecisionMade] = useState(false);

  const revealTitle = useCallback(() => {
    setShowTitle(true);
  }, []);

  const makeDecision = useCallback(() => {
    setDecisionMade(true);
  }, []);

  const initLineScrollTrigger = useCallback(() => {
    const tl = gsap.timeline();
    const scrollTrigger = ScrollTrigger.create({
      trigger: lineSvgRef.current,
      pin: false,
      once: true,
      start: `top-=${vh(30)}`,
      end: `bottom-=${vh(60)}`,
      scrub: 0,
      animation: tl,
    });
    tl.fromTo(lineSvgRef.current, {
      drawSVG: '0%',
    }, {
      drawSVG: '100%',
      ease: 'none',
    }, 0);
    lineAnimationRef.current = tl;
    lineScrollTriggerRef.current = scrollTrigger;
  }, []);

  const initBranchScrollTrigger = useCallback(() => {
    const tl = gsap.timeline();
    const scrollTrigger = ScrollTrigger.create({
      trigger: branchContainerRef.current,
      pin: false,
      once: true,
      start: `top-=${vh(60)}`,
      end: `bottom-=${vh(40)}`,
      scrub: 0,
      animation: tl,
    });
    tl.fromTo(branchContainerRef.current.children, {
      drawSVG: '0%',
    }, {
      drawSVG: '100%',
      ease: 'none',
    }, 0);
    branchAnimationRef.current = tl;
    branchScrollTriggerRef.current = scrollTrigger;
  }, []);

  const initTailScrollTrigger = useCallback(() => {
    const tl = gsap.timeline();
    const scrollTrigger = ScrollTrigger.create({
      trigger: tailSvgRef.current,
      pin: false,
      once: true,
      start: `top-=${vh(40)}`,
      end: `bottom-=${vh(50)}`,
      scrub: 0,
      animation: tl,
      onEnter: makeDecision,
      onLeave: revealTitle,
    });
    tl.fromTo(tailSvgRef.current, {
      drawSVG: '0%',
    }, {
      drawSVG: '100%',
      ease: 'none',
    }, 0);
    tailAnimationRef.current = tl;
    tailScrollTriggerRef.current = scrollTrigger;
  }, []);

  useEffect(() => {
    if (lineSvgRef.current) {
      initLineScrollTrigger();
      return () => {
        lineAnimationRef.current.kill();
        lineScrollTriggerRef.current.kill();
      };
    }
  }, [lineSvgRef]);

  useEffect(() => {
    if (branchContainerRef.current) {
      initBranchScrollTrigger();
      return () => {
        branchAnimationRef.current.kill();
        branchScrollTriggerRef.current.kill();
      };
    }
  }, [branchContainerRef]);

  useEffect(() => {
    if (branchContainerRef.current) {
      initTailScrollTrigger();
      return () => {
        tailAnimationRef.current.kill();
        tailScrollTriggerRef.current.kill();
      };
    }
  }, [branchContainerRef]);

  return (
    <>
      <Section className="intro">
        <div className="intro-container">
          <div className="opening-text">
            <p>Imagine every choice you made created a fork in the road which lead to a parallel world</p>
          </div>
          <div className="branch">
            <div className="line-svg">
              <svg className="svg-line" preserveAspectRatio="none" viewBox="0 0 560 759" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path ref={lineSvgRef} d="M280 0V759" />
              </svg>
            </div>
            <div className="branch-svg">
              <svg ref={branchContainerRef} preserveAspectRatio="none" viewBox="0 0 562 161" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={`${decisionMade ? 'faded' : ''}`} d="M280.5 1L1 137V161" />
                <path d="M280.5 1L561 137.075V161" />
              </svg>
            </div>
          </div>
          <div className="options">
            <p className={`${decisionMade ? 'faded' : ''}`}>Leave Website</p>
            <p>Keep Scrolling</p>
          </div>
          <div className="tail-svg">
            <svg viewBox="0 0 561 580" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path ref={tailSvgRef} d="M560 0V316.157L280.203 407.706V580" />
            </svg>
          </div>
        </div>
      </Section>
      <Section className="intro-text-section" hasGrid>
        <div className="title">
          <div className="title-container">
            <SplitWordsAnimation animationDelay={0} show={showTitle}>
              <h1>Introduction</h1>
            </SplitWordsAnimation>
          </div>
        </div>
        <div className="column column-left">
          <p>A watershed moment describes a dividing point from which life as you know it will never be the same. An action that will change the course of history.</p>
          <p>On a global scale, we can think of the outcome of the second World War, the assassination of John F Kennedy, or the outbreak of COVID-19.</p>
          <p>On a personal level this might be when you decided on a professional pursuit, when you approached a love interest, or the way that you acted in the moments before it fell apart.</p>
        </div>
        <div className="column column-right">
          <p>These are decisive moments in time, often subject to our examination in hindsight. We imagine timelines and outcomes if we had acted differently, whole other worlds born in a chain reaction to that decision.</p>
          <p>An increasingly popular analysis of quantum physics known as “The Many Worlds Interpretation” (MWI) theorises that these alternate realities actually exist. That every fundamental event that results in multiple probable outcomes splits the world into realities where each of those outcomes eventuated.</p>
        </div>
      </Section>
    </>
  );
};

export default Intro;
