export const TRUE_BLUE = '#000AFF';
export const TRUE_RED = '#FE0000';
export const TRUE_GREEN = '#089404';
export const OFF_BLACK = '#222222';

export const brandColors = [
  TRUE_RED,
  TRUE_BLUE,
  TRUE_GREEN,
  OFF_BLACK,
];
