import React, { forwardRef } from 'react';
import './Section.scss';

const Section = forwardRef((props, ref) => {
  const {
    children,
    className,
    tag,
    noBottomMargin,
    fullWidth,
    hasGrid,
  } = props;
  const Tag = tag || 'section';
  const classes = `section-wrapper${className ? ` ${className}` : ''}${hasGrid ? ' has-grid' : ''}${fullWidth ? ' full-width' : ''}${noBottomMargin ? ' no-bottom-margin' : ''}`;
  return (
    <Tag className={classes} ref={ref}>
      {children}
    </Tag>
  );
});
Section.displayName = 'Section';

export default Section;
