import { useRef, useImperativeHandle } from 'react';

export default (ref, createTimeline, dependencies) => {
  const timelineRef = useRef();
  useImperativeHandle(ref, () => ({
    getTimeline: () => {
      if (timelineRef.current == null) {
        timelineRef.current = createTimeline();
      }
      return timelineRef.current;
    },
  }), dependencies);
  return timelineRef;
};
