import React from 'react';
import { RevealingUniverse } from '.';
import './UniverseGrid.scss';

const UniverseGrid = () => (
  <div className="universe-grid">
    <div className="universe-row">
      <div className="universe-slot" />
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
      <div className="universe-slot" />
    </div>
    <div className="universe-row">
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
      <div className="universe-slot" />
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
    </div>
    <div className="universe-row">
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
    </div>
    <div className="universe-row">
      <div className="universe-slot" />
      <div className="universe-slot">
        <RevealingUniverse />
      </div>
      <div className="universe-slot" />
    </div>
  </div>
);

export default UniverseGrid;
