import React, { Component } from 'react';
import Img from 'gatsby-image';
import './Image.scss';


export default class Image extends Component {
  render() {
    const {
      image,
      className = '',
      imageProps = {},
      alt = '',
    } = this.props;
    if (!image) return <div className={`gatsby-image placeholder ${className}`} />;
    const altText = !image.alt ? alt : image.alt;
    if (
      image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid
    ) {
      return (
        <Img
          className={`gatsby-image ${className}`}
          fluid={image.localFile.childImageSharp.fluid}
          alt={altText}
          {...imageProps}
        />
      );
    }
    if (image.url) {
      return <img className={`gatsby-image ${className}`} src={image.url} alt={altText} />;
    }
    return <div className={`gatsby-image placeholder ${className}`} />;
  }
}
