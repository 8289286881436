import React from 'react';
import { SEO } from '..';
import '@fontsource/crimson-text';
import '@fontsource/roboto-mono';
import '../../sass/global/styles.scss';

const Layout = (props) => {
  const {
    children,
    location,
  } = props;
  const { pathname } = location;
  const isHome = Boolean(pathname === '/');
  const mainClasses = `${isHome ? 'home' : location.pathname.replace(/\//g, '')}`;
  return (
    <>
      <SEO
        title={null}
        desc={null}
        banner={null}
        pathname={location.pathname}
      />
      <div id="app" className="app">
        <main className={mainClasses}>
          {children}
        </main>
      </div>
    </>
  );
};

export default Layout;
